

export class Utilities {

    private static daysOfWeek = ["l", "m", "x", "j", "v", "s", "d"];

    // return the price as a string with 2 decimal places and the € symbol
    static formatPrice(value: number): string {
        return value.toFixed(2) + " €";
    }

    public static formatDateToDDMMYYYY(value: string): string {
        if (value && typeof value === "string") {
            return value.split("-").reverse().join("/");
        }

        return value;
    }

    // returns the day of the week for a given date in YYYY-MM-DD format, 0 = Monday, 6 = Sunday
    public static getDayOfWeekYYYYMMDD(value: string): number {
        if (value) {
            const date = value.split("-");
            if (date.length === 3) {
                const day = parseInt(date[2]);
                const month = parseInt(date[1]);
                const year = parseInt(date[0]);

                if (!isNaN(day) && !isNaN(month) && !isNaN(year)) {
                    const dayOfWeek = new Date(year, month - 1, day).getDay();
                    return dayOfWeek === 0 ? 6 : dayOfWeek - 1;
                }
            }
        }

        return -1;
    }

    public static getDayOfWeekNameYYYYMMDD(value: string): string {
        return this.daysOfWeek[Utilities.getDayOfWeekYYYYMMDD(value)] || "";
    }

    public static getBrowserLanguage(): string {
        const userLocale = navigator.language || (navigator as any).userLanguage;
        return userLocale.split("-")[0].toLowerCase();
    }

}
