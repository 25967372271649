import "./ClassComponentHooks";

import Vue from "vue";
import VueMeta from "vue-meta";
import { createPinia, PiniaVuePlugin } from "pinia";

import { DataClient } from "@/services/DataClient";

import { i18n, loadLanguageAsync } from "./i18n";
import { initializeRouter } from "./router";
import vuetify from "./plugins/vuetify";

import App from "./App.vue";

import "./directives/FadeIn";

Vue.component("GlobalFooter", () => import("./components/GlobalFooter.vue"));
Vue.component("SectionDivider", () => import("./components/Divider.vue"));
Vue.component("HomeBanner", () => import("./components/HomeBanner.vue"));
Vue.component("HomeText", () => import("./components/HomeText.vue"));
Vue.component("CategoryPill", () => import("./components/CategoryPill.vue"));
Vue.component("ExclusivePill", () => import("./components/ExclusivePill.vue"));
Vue.component("ImageGallery", () => import("./components/ImageGallery.vue"));

// Image components
Vue.component("AnimatedImageList", () => import("./components/AnimatedImageList.vue"));

// Activity components
Vue.component("VerticalActivityThumbnail", () => import("./components/VerticalActivityThumbnail.vue"));


Vue.config.productionTip = false;

Vue.use(VueMeta);
Vue.use(PiniaVuePlugin);

const pinia = createPinia();

async function bootstrap() {
    await DataClient.instance.loadGlobalConfiguration();

    const { router, initialLocale } = initializeRouter();

    (window as any).__router = router;

    // make sure to set the language and load configuration before creating the app
    Promise.all([
        DataClient.instance.loadConfiguration(initialLocale),
        loadLanguageAsync(initialLocale)
    ]).then(() => {
        document.title = DataClient.instance.configuration.website.name;

        // read parameter "affiliate" from query string and store it in localstorage for later use
        // keep associated affiliate for 24 hours in localstorage
        const affiliate = new URLSearchParams(window.location.search).get("affiliate");
        if (affiliate) {
            localStorage.setItem("affiliate", affiliate);
            localStorage.setItem("affiliateDate", new Date().toISOString());
        }

        new Vue({
            router,
            vuetify,
            pinia,
            i18n,
            render: (h) => h(App),
        }).$mount("#app");
    });
}

bootstrap();

// import "@/services/Sitemap";
